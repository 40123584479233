<template>
  <div class="row justify-content-md-center mt2000">
    <div class="col-lg-4 col-md-5 col-12">
      <div class="card">
        <div class="card-header">
          <h4>Change password</h4>
        </div>
        <div class="card-body">
          <form v-on:submit.prevent="fnUpdatePassword">
            <div class="form-group">
              <label for="oldPassword">Old password</label>
              <input
                type="password"
                class="form-control"
                id="oldPassword"
                placeholder="Password Old"
                v-model="userData.passwordOld"
              />
              <div class="invalid-feedback" v-if="errors.passwordOld">
                {{ errors.passwordOld[0] }}
              </div>
            </div>
            <div class="mt3000">
              <div class="form-group">
                <label for="password">New password</label>
                <input
                  type="password"
                  class="form-control"
                  id="newPassword"
                  placeholder="Password"
                  v-model="userData.password"
                />
                <div class="invalid-feedback" v-if="errors.password">
                  {{ errors.password[0] }}
                </div>
              </div>
              <div class="form-group">
                <label for="passwordConfirm">Confirm password</label>
                <input
                  type="password"
                  class="form-control"
                  id="passwordConfirm"
                  placeholder="Password Confirm"
                  v-model="userData.passwordConfirm"
                />
                <div class="invalid-feedback" v-if="errors.passwordConfirm">
                  {{ errors.passwordConfirm[0] }}
                </div>
              </div>
            </div>
            <div class="text-right">
              <button
                type="button"
                class="btn btn-secondary btn-sm mr-2"
                @click="fnCancel()"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-success btn-sm">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import * as messageService from "../../services/message.service";
import * as authService from "../../services/auth.service";
export default {
  name: "passwordChange",
  components: {},
  data() {
    return {
      errors: {},
      userData: {
        passwordOld: "",
        password: "",
        passwordConfirm: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUserProfile"]),
  },

  methods: {
    fnUpdatePassword: async function () {
      let formData = new FormData();
      formData.append("passwordOld", this.userData.passwordOld);
      formData.append("password", this.userData.password);
      formData.append("passwordConfirm", this.userData.passwordConfirm);
      try {
        const response = await authService.changePassword(formData);
        this.$router.push({ name: "login" });
      } catch (error) {
        switch (error.response.status) {
          case 401:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Changed password",
              error.response.data.message
            );
            break;
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Changed password",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Changed password",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Changed password",
              "Something wrong, please try again!"
            );
            break;
        }
      }
      this.userData = {};
    },
    fnCancel() {
      if (
        this.getUserProfile.role_id == 1 ||
        this.getUserProfile.role_id == 2
      ) {
        this.$router.push({ name: "dashboardAdmin" });
      } else if (
        this.getUserProfile.role_id == 3 ||
        this.getUserProfile.role_id == 9
      ) {
        this.$router.push({ name: "dashboardIU" });
      } else if (
        this.getUserProfile.role_id == 4 ||
        this.getUserProfile.role_id == 5
      ) {
        this.$router.push({ name: "dashboardCM" });
      } else if (
        this.getUserProfile.role_id == 6 ||
        this.getUserProfile.role_id == 7
      ) {
        this.$router.push({ name: "dashboardPartner" });
      }
    },
  },
};
</script>